// @ts-nocheck
export * from './address.service';
import { AddressService } from './address.service';
export * from './appointment.service';
import { AppointmentService } from './appointment.service';
export * from './assessment.service';
import { AssessmentService } from './assessment.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './calendar.service';
import { CalendarService } from './calendar.service';
export * from './categorizationProvider.service';
import { CategorizationProviderService } from './categorizationProvider.service';
export * from './companyDetails.service';
import { CompanyDetailsService } from './companyDetails.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './eligibilityReport.service';
import { EligibilityReportService } from './eligibilityReport.service';
export * from './eligibleType.service';
import { EligibleTypeService } from './eligibleType.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './employer.service';
import { EmployerService } from './employer.service';
export * from './loginVerification.service';
import { LoginVerificationService } from './loginVerification.service';
export * from './medicalExamination.service';
import { MedicalExaminationService } from './medicalExamination.service';
export * from './medicalExaminationType.service';
import { MedicalExaminationTypeService } from './medicalExaminationType.service';
export * from './occupation.service';
import { OccupationService } from './occupation.service';
export * from './occupationAssessibleItem.service';
import { OccupationAssessibleItemService } from './occupationAssessibleItem.service';
export * from './occupationCategorization.service';
import { OccupationCategorizationService } from './occupationCategorization.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './orderRcmd.service';
import { OrderRcmdService } from './orderRcmd.service';
export * from './priceList.service';
import { PriceListService } from './priceList.service';
export * from './provider.service';
import { ProviderService } from './provider.service';
export * from './templates.service';
import { TemplatesService } from './templates.service';
export * from './tenant.service';
import { TenantService } from './tenant.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './workplace.service';
import { WorkplaceService } from './workplace.service';
export const APIS = [AddressService, AppointmentService, AssessmentService, AuthService, CalendarService, CategorizationProviderService, CompanyDetailsService, DashboardService, EligibilityReportService, EligibleTypeService, EmployeeService, EmployerService, LoginVerificationService, MedicalExaminationService, MedicalExaminationTypeService, OccupationService, OccupationAssessibleItemService, OccupationCategorizationService, OrderService, OrderRcmdService, PriceListService, ProviderService, TemplatesService, TenantService, UserService, WorkplaceService];
