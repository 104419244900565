<ng-container *ngIf="hasItems">
<div class="employer-filter">
  <nb-form-field>
    <nb-icon nbPrefix icon="employer" pack="icons-medicloud" class="custom-icon"></nb-icon>
    <nb-select
      fullWidth
      (selectedChange)="handleSelection($event)"
      (click)="selectorOpen()"
      [selected]="selectedEmployerId"
      [disabled]="disable$ | async"
      placeholder="Zvoľte zamestnávateľa">
      <nb-select-label>
        {{ getSelectedEmployerAlias() }}<button *ngIf="canDeselect" nbButton ghost size="tiny" (click)="clearSelection()"><nb-icon icon="close-outline"></nb-icon></button>
      </nb-select-label>
      <nb-option *ngFor="let employer of employers" [value]="employer.id">{{ employer.alias }}</nb-option>
    </nb-select>
  </nb-form-field>
</div>
</ng-container>
