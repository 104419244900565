import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { OccupationAssessibleItem, OccupationAssessibleItemService } from 'src/api-new';
import { OverlayLoaderService } from 'src/app/services/overlay-loader.service';
import { UserDetailService } from 'src/app/services/user-detail.service';
import { AddFactorActivityModalComponent } from '../add-factor-activity-modal/add-factor-activity-modal.component';

@Component({
  selector: 'app-occupation-detail',
  templateUrl: './occupation-detail.component.html',
  styleUrls: ['./occupation-detail.component.scss']
})
export class OccupationDetailComponent implements OnInit {

  @Input() employerId: number;
  @Input() occupationId: number;
  @Input() occupationName: string;

  userRole = this.userDetailService.role;

  occupationAssessibleItems$: Observable<OccupationAssessibleItem[]>;
  loading$: Observable<boolean>;

  occupationAssessibleItems: OccupationAssessibleItem[];

  refreshData = new BehaviorSubject(null);

  constructor(
    private overlayLoader: OverlayLoaderService,
    private userDetailService: UserDetailService,
    private occupationAssessibleItemService: OccupationAssessibleItemService,
    private nbDialog: NbDialogService,
    private nbToastr: NbToastrService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.loading$ = this.overlayLoader.loading$;
    this.overlayLoader.set(true);
    this.occupationAssessibleItems$ = this.refreshData.pipe(
      mergeMap(() => {
        return this.activatedRoute.queryParams.pipe(
          switchMap((params) => {
            return this.occupationAssessibleItemService.occupationAssessibleItemGetByCategorizationAndOccupation(params.categorizationId, this.occupationId).pipe(
              tap((res) => this.occupationAssessibleItems = res),
              tap(() => this.overlayLoader.set(false))
            )
          })
        )
      })
    )
  }

  showTitle(dtype: string): boolean {
    if (this.occupationAssessibleItems.some((item) => item.assessibleItem?.dtype === dtype)) return true
    else return false
  }

  addItem() {
    this.nbDialog.open(
      AddFactorActivityModalComponent,
      {
        context: {
          occupationId: this.occupationId,
          employerId: this.employerId,
          occupationAssessibleItems: this.occupationAssessibleItems
        }
      }
    ).onClose.subscribe(
      res => {
        if (res) {
          this.refreshData.next(null);
        }
      }
    );
  }


  removeItem(id: number) {
    this.overlayLoader.set(true);
    this.occupationAssessibleItemService.occupationAssessibleItemDelete(id).subscribe(
      () => {
        this.refreshData.next(null);
      },
      (err) => {
        this.nbToastr.danger('Chyba v komunikácii so serverom', err.status, {duration: 5000});
        this.overlayLoader.set(false)
      }
    )
  }

}
