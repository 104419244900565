import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {
  AddressApiModel,
  EmployerApiModel, Provider, ProviderApiModel,
  ProviderService,
  ProviderTenantCreateApiModel, ProviderUpdateApiModel,
  UserRegistrationApiModel
} from '../../../../../api-new';
import { controlInputCompanyId, SubscriptionContainer } from '../../../../utils';
import { TableLoaderService } from 'src/app/services/table-loader.service';
import { DialogAction } from '../../../shared/components/dialog-wrapper/dialog-wrapper.component';
import {
  AddEditEmployerDialogComponent
} from "../../../shared/components/add-edit-employer-dialog/add-edit-employer-dialog.component";

@Component({
  selector: 'app-add-edit-provider-modal',
  templateUrl: './add-edit-provider-modal.component.html',
  styleUrls: ['./add-edit-provider-modal.component.scss']
})
export class AddEditProviderModalComponent implements OnInit, OnDestroy {

  provider: ProviderTenantCreateApiModel;
  providerEdit: ProviderApiModel;
  selectedEmployer: any;

  actions: Array<DialogAction> = [
    {
      label: 'Zrušiť',
      status: "basic",
      click: () => this.cancel()
    },
    {
      label: 'Potvrdiť',
      status: 'primary',
      click: () => this.save()
    }
  ];

  fg: UntypedFormGroup;
  editMode = false;
  loading = false;
  passwordsNotMatch: boolean;

  subs = new SubscriptionContainer();

  hiddenControlSubject = new BehaviorSubject<boolean>(false);
  triggerValidation = false;
  address: AddressApiModel;

  constructor(
    private ref: NbDialogRef<AddEditProviderModalComponent>,
    private fb: UntypedFormBuilder,
    private providerService: ProviderService,
    private nbToast: NbToastrService,
    private tableLoader: TableLoaderService,
    private dialogService: NbDialogService,
  ) {
  }

  ngOnInit() {
    console.log(this.providerEdit);
    this.hiddenControlSubject.next(this.editMode);
    if (this.editMode) {
      this.fg = this.fb.group({
        companyName: ['', [Validators.required]],
        companyIco: ['', [Validators.required]],
      });

      this.fg.patchValue({
        companyName: this.providerEdit.name,
        companyIco: this.providerEdit.ico,
      });
      this.address = this.providerEdit.address;

    } else {
      this.fg = this.fb.group({
        companyName: ['', [Validators.required]],
        companyIco: ['', [Validators.required]],
        effectiveSince: ['', [Validators.required]],
        effectiveUntil: [''],
        username: ['', [Validators.required]],
        email: ['', [Validators.required]],
        password: ['', [Validators.required]],
        repeatPassword: ['', [Validators.required]],
      });
      this.address = {};
      this.provider = {
        user: {
          username: this.fg.value.username,
          displayName: this.fg.value.username,
          email: this.fg.value.email,
          password: this.fg.value.password,
        },
        tenantName: this.fg.value.companyName,
        companyName: this.fg.value.companyName,
        companyIco: this.fg.value.companyIco,
        effectiveSince: this.fg.value.effectiveSince ? new Date(this.fg.value.effectiveSince.setHours(12)).toISOString() : undefined,
        effectiveUntil: this.fg.value.effectiveUntil ? new Date(this.fg.value.effectiveUntil.setHours(12)).toISOString() : undefined,
        addressDto: this.address
      }

      if (this.selectedEmployer) {
        this.fg.patchValue({
          companyName: this.selectedEmployer.name,
          companyIco: this.selectedEmployer.ico,
        });
        this.provider.addressDto = this.selectedEmployer.addressDto;
      } else {
        if (this.provider && this.provider.companyName)
          this.fg.patchValue({
            companyName: this.provider.companyName,
            companyIco: this.provider.companyIco,
          });
        else
          this.fg.patchValue({
            companyName: '',
            companyIco: '',
          });
      }
    }

    this.hiddenControlSubject.next(false);

    this.fg.valueChanges.subscribe(form => {
      if (form.companyIco) {
        this.fg.patchValue({
          companyIco: controlInputCompanyId(form.companyIco)
        }, { emitEvent: false });
      }
      if (form.password || form.repeatPassword) {
        this.passwordsNotMatch = this.checkPasswordsMatch();
      }
    });
  }

  ngOnDestroy() {
    this.subs.dispose();
  }

  cancel() {
    this.ref.close(false);
  }

  save() {
    if(this.fg.invalid) {
      this.triggerValidation = true;
      this.fg.markAllAsTouched();
      return;
    }

    if(this.editMode) {
      const dto: ProviderUpdateApiModel = {
        ico: this.fg.value.companyIco,
        id: this.providerEdit.id,
        name: this.fg.value.companyName,
        address: this.address
      };
      this.providerService.providerUpdateProvider(dto).subscribe(
        () => this.ref.close(true),
        (err) => {
          this.tableLoader.set(false);
          this.nbToast.danger('Nastala chyba pri upravovaní prevádzkovateľa.', err.status, { duration: 5000 })
        }
      );
    }

    if (!this.passwordsNotMatch) {
      this.tableLoader.set(true);
      if (!this.editMode) {
        const user: UserRegistrationApiModel = {
          username: this.fg.value.username,
          displayName: this.fg.value.username,
          email: this.fg.value.email,
          password: this.fg.value.password,
        };
        const dto: ProviderTenantCreateApiModel = {
          user: user,
          tenantName: this.fg.value.companyName,
          companyName: this.fg.value.companyName,
          companyIco: this.fg.value.companyIco,
          effectiveSince: this.fg.value.effectiveSince ? new Date(this.fg.value.effectiveSince.setHours(12)).toISOString() : undefined,
          effectiveUntil: this.fg.value.effectiveUntil ? new Date(this.fg.value.effectiveUntil.setHours(12)).toISOString() : undefined,
          addressDto: this.address
        }
        this.subs.add = this.providerService.providerCreateWithTenantAndUser(dto)
          .subscribe(
            () => this.ref.close(true),
            (err) => {
              this.tableLoader.set(false);
              this.nbToast.danger('Nastala chyba pri pridávaní prevádzkovateľa.', err.status, { duration: 5000 })
            }
          )
      }
    } else {
      this.nbToast.danger('Heslá nie sú rovnaké.', 'Chyba', { duration: 5000 });
    }
  }

  checkPasswordsMatch() {
    return this.fg.value.password === this.fg.value.repeatPassword ? false : true;
  }

  onAddressDataChanged(updatedData: AddressApiModel): void {
    this.triggerValidation = false;
    this.address = updatedData;
  }

  public onIcoClick(): void {
    const dialogRef = this.dialogService.open(AddEditEmployerDialogComponent, {
      context: {
        typeOfProvider: 'inline'
      }
    });

    dialogRef.onClose.subscribe((result:EmployerApiModel) => {
      if (result) {
        this.provider.addressDto = result.address;
        this.fg.patchValue({
          companyName: result.name,
          companyIco: result.ico,
        });
      }
    });
  }

}
