<app-dialog-wrapper [actions]="actions">
  <h3 class="col-12">{{ editMode ? 'Upraviť detaily' : 'Detail' }} zamestnávateľa</h3>
  <form [formGroup]="fg" autocomplete="off">
    <div class="section">Údaje zamestnávateľa:</div>
    <div class="inputs-group">
      <mat-form-field appearance="fill">
        <mat-label>IČO</mat-label>
        <div style="display: flex; flex-direction: row">
          <input matInput name="CompanyIco" placeholder="Identifikačné číslo" formControlName="companyIco"
                 matTooltip="Identifikačné číslo organizácie" [ngClass]="{hidden: hiddenControlSubject | async}">
          <button mat-icon-button style="color: orange" class="icon-button" (click)="onIcoClick()">
            <nb-icon icon="edit-2-outline"></nb-icon>
          </button>
        </div>
        <mat-error *ngIf="fg.get('companyIco')?.hasError('required')">
          IČO je povinné.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Meno zamestnávateľa</mat-label>
        <textarea style="resize: none" matInput name="companyName" formControlName="companyName"
                  [ngClass]="{hidden: hiddenControlSubject | async}" required></textarea>
        <mat-error *ngIf="fg.get('companyName')?.hasError('required')">
          Meno zamestnávateľa je povinné.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Skrátený názov zamestnávatela</mat-label>
        <input matInput name="alias" formControlName="alias"
               [ngClass]="{hidden: hiddenControlSubject | async}" required>
        <mat-error *ngIf="fg.get('alias')?.hasError('required')">
          Skrátený názov je povinný.
        </mat-error>
        <mat-error *ngIf="fg.get('alias')?.hasError('maxlength')">
          Skrátený názov môže obsahovať max. 12 znakov.
        </mat-error>
      </mat-form-field>
    </div>

    <app-edit-address [oldInputDesign]="false"
                      [triggerValidation]="triggerValidation"
                      [addressData]="employer.address" [isEditable]="editMode"
                      (outputAddressData)="onAddressDataChanged($event)"
                      [ngClass]="{hidden: hiddenControlSubject | async}">
    </app-edit-address>

    <ng-container *ngIf="!editMode">
      <div class="inputs-group">
        <mat-form-field appearance="fill">
          <mat-label>Aktívny od</mat-label>
          <input matInput name="effectiveSince" formControlName="effectiveSince"
                 [ngClass]="{hidden: hiddenControlSubject | async}" [matDatepicker]="datepicker1" required>
          <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
          <mat-datepicker #datepicker1></mat-datepicker>
          <mat-error *ngIf="fg.get('effectiveSince')?.hasError('required')">
            Dátum "Aktívny od" je povinný.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Aktívny do</mat-label>
          <input matInput name="effectiveUntil" formControlName="effectiveUntil"
                 [ngClass]="{hidden: hiddenControlSubject | async}" [matDatepicker]="datepicker2">
          <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
          <mat-datepicker #datepicker2></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Priradená PZS</mat-label>
          <mat-select name="pzsId" formControlName="pzsId"
                      [ngClass]="{hidden: hiddenControlSubject | async}">
            <mat-option *ngFor="let option of employees$ | async" [value]="option.value"
                        [title]="option.label">
              {{ option.label | truncate : 35 }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fg.get('pzsId')?.hasError('required')">
            Priradená PZS je povinná.
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="!editMode">
      <div class="section">Prihlasovacie údaje:</div>
      <div class="inputs-group">

        <mat-form-field appearance="fill">
          <mat-label>Meno používateľa</mat-label>
          <input matInput name="username" formControlName="username"
                 [ngClass]="{hidden: hiddenControlSubject | async}" required>
          <mat-error *ngIf="fg.get('username')?.hasError('required')">
            Meno používateľa je povinné.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput name="email" formControlName="email"
                 [ngClass]="{hidden: hiddenControlSubject | async}" required>
          <mat-error *ngIf="fg.get('email')?.hasError('required')">
            Email je povinný.
          </mat-error>
          <mat-error *ngIf="fg.get('email')?.hasError('email')">
            Zadajte platný email.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputs-group">
        <mat-form-field appearance="fill">
          <mat-label>Heslo</mat-label>
          <input matInput name="password" formControlName="password" autocomplete="off" type="password"
                 [ngClass]="{hidden: hiddenControlSubject | async}" required>
          <mat-error *ngIf="fg.get('password')?.hasError('required')">
            Heslo je povinné.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Zopakujte heslo</mat-label>
          <input matInput name="repeatPassword" formControlName="repeatPassword" autocomplete="off"
                 type="password" [ngClass]="{hidden: hiddenControlSubject | async}" required>
          <mat-error *ngIf="fg.get('repeatPassword')?.hasError('required')">
            Zopakujte heslo.
          </mat-error>
          <mat-error *ngIf="passwordsNotMatch">
            Heslá sa nezhodujú.
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </form>
</app-dialog-wrapper>
