import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { OccupationAssessibleItem, OccupationAssessibleItemService, OccupationCategorizationService } from 'src/api-new';
import { OverlayLoaderService } from 'src/app/services/overlay-loader.service';
import { DialogAction } from '../../dialog-wrapper/dialog-wrapper.component';
import { QuickForm } from '../../quick-form/quick-form.component';

@Component({
  selector: 'app-add-occupation-assessible-modal',
  templateUrl: './add-occupation-assessible-modal.component.html',
  styleUrls: ['./add-occupation-assessible-modal.component.scss']
})
export class AddOccupationAssessibleModalComponent implements OnInit, OnDestroy {

  occupationId: number;
  employerId: number;
  occupationAssessibleItems: OccupationAssessibleItem[];
  categorizationId: number;
  loading = false;
  noFreeRiskFactor: boolean;
  noFreeWorkingActivity: boolean;

  actions: Array<DialogAction> = [
    {label: 'Zrušiť', status: 'basic', click: () => this.ref.close(false)},
    {
      label: 'Potvrdiť',
      status: 'primary',
      click: () => this.save(),
      disabled: () => !this.fg || this.fg.value.assessibleItem === null || this.fg.value.dateFrom === null
    }
  ];

  fg: UntypedFormGroup;
  optionsSubject = new Subject<Array<{label: string, value: number | null}>>();
  hiddenAssessibleTypeSubject = new BehaviorSubject<boolean>(true);
  formDef: QuickForm;

  formChangeSub = Subscription.EMPTY;

  constructor(
    private overlayLoader: OverlayLoaderService,
    private ref: NbDialogRef<AddOccupationAssessibleModalComponent>,
    private categorizationService: OccupationCategorizationService,
    private activatedRoute: ActivatedRoute,
    private occupationAssessibleItemService: OccupationAssessibleItemService,
    private nbToastr: NbToastrService,
    ) { }

  ngOnInit(): void {
    this.formDef = {
      controls: [{
        formControlName: 'kindOfAssessibleItem',
        placeholder: 'Druh',
        type: 'select',
        validators: [Validators.required],
        optionsObservable: of([{label: 'Rizikový faktor', value: 1}, {label: 'Vybraná činnosť', value: 2}])
      }, {
        disabled: false,
        hidden: this.hiddenAssessibleTypeSubject,
        formControlName: 'assessibleItem',
        placeholder: 'Typ *',
        type: 'select',
        validators: [Validators.required],
        optionsObservable: this.optionsSubject
      }]
    };
  }

  formInit(fg: UntypedFormGroup) {
    this.fg = fg;
    this.formChangeSub = this.activatedRoute.queryParams.pipe(
      // tslint:disable-next-line:radix
      tap((params) => this.categorizationId = parseInt(params.categorizationId)),
      switchMap(() => {
        return this.fg.controls.kindOfAssessibleItem.valueChanges.pipe(
          tap(() => this.noFreeRiskFactor = false),
          tap(() => this.noFreeWorkingActivity = false),
          tap(() => this.loading = true),
          switchMap(kind => {
            switch (kind) {
              case 1: return this.categorizationService.occupationCategorizationGetRiskFactors(this.categorizationId).pipe(
                map((riskFactors) => riskFactors.filter((riskFactor => {
                  return !this.occupationAssessibleItems.some(
                    (item) => item.assessibleItem?.code === riskFactor.code
                  );
                }))),
                map(rfs => rfs.map(rf => ({label: rf.name ?? '', value: rf.id}))),
                tap((rfs) => this.noFreeRiskFactor = rfs.length === 0),
                tap(() => this.loading = false),
              );
              case 2: return this.categorizationService.occupationCategorizationGetWorkActivities(this.categorizationId).pipe(
                map((workActivities) => workActivities.filter((workActivity => {
                  return !this.occupationAssessibleItems.some(
                    (item) => item.assessibleItem?.code === workActivity.code
                  );
                }))),
                map(was => was.map(wa => ({label: wa.name ?? '', value: wa.id}))),
                tap((was) => this.noFreeWorkingActivity = was.length === 0),
                tap(() => this.loading = false),
              );
              default: return of([{label: '', value: null}]).pipe(
                tap(() => this.loading = false));
            }
          })
        );
      })
    )
.subscribe(res => {
      this.fg.patchValue({assessibleItem: null});
      this.optionsSubject.next(res);
      this.hiddenAssessibleTypeSubject.next(this.noFreeRiskFactor || this.noFreeWorkingActivity);
    });
  }

  save() {
    this.overlayLoader.set(true);
    const dto: OccupationAssessibleItem = {
      id: 0,
      categorizationId: this.categorizationId,
      assessibleItemId: this.fg.value.assessibleItem,
      occupationId: this.occupationId,
    };
    this.occupationAssessibleItemService.occupationAssessibleItemCreate(dto).subscribe(
      () => {
        this.ref.close(true);
      },
      (err) => {
        this.nbToastr.danger('Chyba v komunikácii so serverom', err.status, {duration: 5000});
      }
    );
  }

  ngOnDestroy() {
    this.formChangeSub.unsubscribe();
  }
}
