import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { NbDialogRef, NbToastrService, NbDialogService } from '@nebular/theme';
import {
  AddressApiModel,
  EmployerApiModel,
  EmployerService,
  CategorizationProviderService,
  EmployerTenantCreateApiModel,
  UserRegistrationApiModel,
  EmployerUpdateApiModel,
} from "../../../../../api-new";
import { controlInputCompanyId, SubscriptionContainer } from '../../../../utils';
import { TableLoaderService } from 'src/app/services/table-loader.service';
import { map, tap } from "rxjs/operators";
import { DialogAction } from '../../../shared/components/dialog-wrapper/dialog-wrapper.component';
import {
  AddEditEmployerDialogComponent
} from "../../../shared/components/add-edit-employer-dialog/add-edit-employer-dialog.component";

@Component({
  selector: 'app-add-edit-employer-modal',
  templateUrl: './add-edit-employer-modal.component.html',
  styleUrls: ['./add-edit-employer-modal.component.scss']
})
export class AddEditEmployerModalComponent implements OnDestroy,OnInit {

  employer: EmployerApiModel;
  selectedEmployer: any;
  triggerValidation = false;

  actions: Array<DialogAction> = [
    {
      label: 'Zrušiť',
      status: "basic",
      click: () => this.cancel()
    },
    {
      label: 'Potvrdiť',
      status: 'primary',
      click: () => this.save(),
    }
  ];

  fg: UntypedFormGroup;
  editMode = false;
  loading = false;
  passwordsNotMatch: boolean;

  subs = new SubscriptionContainer();
  hiddenControlSubject = new BehaviorSubject<boolean>(false);
  employees$: Observable<any>;

  constructor(
    private ref: NbDialogRef<AddEditEmployerModalComponent>,
    private fb: UntypedFormBuilder,
    private employerService: EmployerService,
    private categorizationProviderService: CategorizationProviderService,
    private nbToast: NbToastrService,
    private tableLoader: TableLoaderService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.editMode = this.employer && this.employer.id ? true : false;
    this.hiddenControlSubject.next(this.editMode);
    this.employees$ = this.categorizationProviderService.categorizationProviderGetAll().pipe(map(res => res.map(item => ({ label: item.name ?? '', value: item.providerId }))), tap(() => this.loading = false));
    if (this.editMode) {
      this.fg = this.fb.group({
        companyName: ['', [Validators.required]],
        companyIco: ['', [Validators.required]],
        alias: ['', [Validators.required,Validators.maxLength(12)]],
      });

      this.fg.patchValue({
        companyName: this.employer.name,
        companyIco: this.employer.ico,
        alias: this.employer.alias,
      });
    } else {

      this.fg = this.fb.group({
        companyName: ['', [Validators.required]],
        companyIco: ['', [Validators.required]],
        alias: ['', [Validators.required, Validators.maxLength(12)]],
        effectiveSince: ['', [Validators.required]],
        effectiveUntil: [''],
        pzsId: ['', [Validators.required]],
        username: ['', [Validators.required]],
        email: ['', [Validators.required]],
        password: ['', [Validators.required]],
        repeatPassword: ['', [Validators.required]],
      });
      if (this.selectedEmployer) {
        this.fg.patchValue({
          companyName: this.selectedEmployer.name,
          companyIco: this.selectedEmployer.ico,
          alias: this.selectedEmployer.alias,
        });
        this.employer.address = this.selectedEmployer.addressDto;
      } else {
        if (this.employer && this.employer.name)
          this.fg.patchValue({
            companyName: this.employer.name,
            companyIco: this.employer.ico,
            alias: this.setAlias(),
          });
        else
          this.fg.patchValue({
            companyName: '',
            companyIco: '',
            alias: '',
          });
      }
    }

    this.hiddenControlSubject.next(false);

    this.fg.valueChanges.subscribe(form => {
      if (form.companyIco) {
        this.fg.patchValue({
          companyIco: controlInputCompanyId(form.companyIco)
        }, { emitEvent: false });
      }
      if (form.password || form.repeatPassword) {
        this.passwordsNotMatch = this.checkPasswordsMatch();
      }
    });
  }

  private setAlias() : string {
    return this.employer.name.replace(',', '').trim().substring(0, 12);
  }

  cancel() {
    this.ref.close(false);
  }

  save() {

    if(this.fg.invalid) {
      if(!this.editMode){
        this.triggerValidation = true;
      }
      this.fg.markAllAsTouched();
      return;
    }

    if (!this.passwordsNotMatch) {
      this.tableLoader.set(true);
      if (this.editMode) {
        this.triggerValidation = false;
        const dto: EmployerUpdateApiModel = {
          id: this.employer.id,
          name: this.fg.value.companyName,
          ico: this.fg.value.companyIco,
          addressDto: this.employer.address,
          alias: this.fg.value.alias
        }
        this.subs.add = this.employerService.employerUpdate(dto).subscribe(
          () => this.ref.close(true),
          (err) => {
            this.tableLoader.set(false);
            this.nbToast.danger('Nastala chyba pri pridávaní zamestnávateľa.', err.status, { duration: 5000 })
          }
        )

      } else {
        const user: UserRegistrationApiModel = {
          username: this.fg.value.username,
          displayName: this.fg.value.username,
          email: this.fg.value.email,
          password: this.fg.value.password,
        };
        const dto: EmployerTenantCreateApiModel = {
          assignedCategoryProviderId: this.fg.value.pzsId,
          user: user,
          tenantName: this.fg.value.companyName,
          companyName: this.fg.value.companyName,
          companyIco: this.fg.value.companyIco,
          effectiveSince: this.fg.value.effectiveSince ? new Date(this.fg.value.effectiveSince.setHours(12)).toISOString() : undefined,
          effectiveUntil: this.fg.value.effectiveUntil ? new Date(this.fg.value.effectiveUntil.setHours(12)).toISOString() : undefined,
          addressDto: this.employer.address,
          alias: this.fg.value.alias
        }
        this.subs.add = this.employerService.employerCreateEmployerWithTenantAndUser(dto)
          .subscribe(
            () => this.ref.close(true),
            (err) => {
              this.tableLoader.set(false);
              this.nbToast.danger('Nastala chyba pri pridávaní zamestnávateľa.', err.status, { duration: 5000 })
            }
          )
      }
    } else {
        this.nbToast.danger('Heslá nie sú rovnaké.', 'Chyba', { duration: 5000 });
    }
  }

  checkPasswordsMatch() {
    return this.fg.value.password === this.fg.value.repeatPassword ? false : true;
  }

  ngOnDestroy() {
    this.subs.dispose();
  }

  onAddressDataChanged(updatedData: AddressApiModel): void {
    this.employer.address = updatedData;
  }

  public onIcoClick(): void {
    const dialogRef = this.dialogService.open(AddEditEmployerDialogComponent, {
      context: {
        typeOfProvider: 'inline'
      }
    });

    dialogRef.onClose.subscribe((result: EmployerApiModel) => {
      if (result) {
        this.employer.address = result.address;
        this.fg.patchValue({
          companyName: result.name,
          companyIco: result.ico,
          alias: result.alias
        });
      }
    });
  }

}

