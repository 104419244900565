import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard  {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.loggedIn.pipe(
      take(1),
      map(loggedIn => loggedIn ? this.router.parseUrl('/domov') : true)
    );
  }
}
