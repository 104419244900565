import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AddressService } from './api/address.service';
import { AppointmentService } from './api/appointment.service';
import { AssessmentService } from './api/assessment.service';
import { AuthService } from './api/auth.service';
import { CalendarService } from './api/calendar.service';
import { CategorizationProviderService } from './api/categorizationProvider.service';
import { CompanyDetailsService } from './api/companyDetails.service';
import { DashboardService } from './api/dashboard.service';
import { EligibilityReportService } from './api/eligibilityReport.service';
import { EligibleTypeService } from './api/eligibleType.service';
import { EmployeeService } from './api/employee.service';
import { EmployerService } from './api/employer.service';
import { LoginVerificationService } from './api/loginVerification.service';
import { MedicalExaminationService } from './api/medicalExamination.service';
import { MedicalExaminationTypeService } from './api/medicalExaminationType.service';
import { OccupationService } from './api/occupation.service';
import { OccupationAssessibleItemService } from './api/occupationAssessibleItem.service';
import { OccupationCategorizationService } from './api/occupationCategorization.service';
import { OrderService } from './api/order.service';
import { OrderRcmdService } from './api/orderRcmd.service';
import { PriceListService } from './api/priceList.service';
import { ProviderService } from './api/provider.service';
import { TemplatesService } from './api/templates.service';
import { TenantService } from './api/tenant.service';
import { UserService } from './api/user.service';
import { WorkplaceService } from './api/workplace.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
