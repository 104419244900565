/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressApiModel } from './addressApiModel';


export interface EmployeeApiModel { 
    id: number;
    tenantEmployerId: number;
    employerId: number;
    addressId: number;
    name: string;
    surname: string;
    dateOfBirth: string;
    inlineAddress: string;
    email: string;
    phoneNumber: string;
    birthNumber: string;
    personalNumber?: string;
    active: boolean;
    hasEntryExamination: boolean;
    address: AddressApiModel;
}

