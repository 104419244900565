<button nbButton status="basic" shape="round" style="margin-bottom: 1.9rem" (click)="back()">
  <nb-icon icon="arrow-back-outline"></nb-icon>
</button>
<div *ngIf="order" class="evaluation-wrapper">
  <div class="evaluation-header">
    <h3><span style="font-size: 1.25rem">Detail posudku pre: </span><span style="font-size: 1.625rem">{{order.employee.surname}} {{order.employee.name}}</span></h3>
    <div style="text-align: end;">
      <span style="font-size: 1.25rem">Ref. číslo: {{order.number}}</span>
      <span class="assessment-type">
        Typ prehliadky: {{getExaminationTypeName(order.type)}}
      </span>
    </div>
  </div>

  <div class="evaluation-body">
    <p class="info-paragraph">
      Faktory práce a pracovného prostredia/ Kategória práce pre jednotlivé faktory práce a pracovného prostredia:
    </p>

    <div *ngIf="order.assessedRiskFactors?.length > 0">
      <div *ngFor="let assessedItem of order.assessedRiskFactors">
        <p class="action-name">{{assessedItem.name}}</p>
        <ng-container *ngIf="assessedItem.result === 'ELIGIBLE'">
          <span class="action-result">spôsobilý na výkon posudzovanej práce na {{assessedItem.maxPeriod}} mesiacov</span>
        </ng-container>
        <ng-container *ngIf="assessedItem.result === 'LIMITED'">
          <span class="action-result">spôsobilý na výkon posudzovanej práce s dočasným obmedzením {{assessedItem.timeIntervalLimit}} mesiacov</span>
        </ng-container>
        <ng-container *ngIf="assessedItem.result === 'NONELIGIBLE'">
          <span class="action-result">dlhodobo nespôsobilý na výkon posudzovanej práce</span>
        </ng-container>
        <div style="margin-top: 0.25rem">
          Poznámka: {{assessedItem.note}}
        </div>
      </div>
      <hr>
    </div>

    <div *ngIf="order.assessedWorkActivities?.length > 0">
      <p class="info-paragraph">
        Práca podľa osobitných predpisov (práce súvisiace s pracovným zaradením zamestnanca):
      </p>

      <div *ngFor="let assessedItem of order.assessedWorkActivities">
        <p class="action-name">{{assessedItem.name}}</p>
        <ng-container *ngIf="assessedItem.result === 'ELIGIBLE'">
          <span class="action-result">spôsobilý na výkon posudzovanej práce na {{assessedItem.maxPeriod}} mesiacov</span>
        </ng-container>
        <ng-container *ngIf="assessedItem.result === 'LIMITED'">
          <span class="action-result">spôsobilý na výkon posudzovanej práce s dočasným obmedzením {{assessedItem.timeIntervalLimit}} mesiacov</span>
        </ng-container>
        <ng-container *ngIf="assessedItem.result === 'NONELIGIBLE'">
          <span class="action-result">dlhodobo nespôsobilý na výkon posudzovanej práce</span>
        </ng-container>
        <div style="margin-top: 0.25rem">
          Poznámka: {{assessedItem.note}}
        </div>
      </div>
      <hr>
    </div>

    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header>
          <span class="info-paragraph">
            Vykonali sme nasledovné lekárske vyšetrenia:
          </span>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div class="examinations-grid">
            <div>Vyšetrenie</div>
            <div>Nález</div>
            <div>Vykonané</div>

            <ng-container *ngFor="let examination of order.examinations">
              <div>{{examination.name}}</div>
              <div>{{examination.result}}</div>
              <div>{{examination.examinationDate | date}}</div>
            </ng-container>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
    <hr>
    <p>Zamestnávateľ:</p>
    <nb-card>
      <nb-card-body>
        <div class="info-card-grid">
          <div class="info-card-item">
            <span class="light">Obchodné meno</span><br>
            {{order.employer.name}}
          </div>
          <div class="info-card-divider"></div>
          <div class="info-card-item">
            <span class="light">IČO</span><br>
            {{order.employer.ico | companyid}}
          </div>
          <div class="info-card-divider"></div>
          <div class="info-card-item">
            <span class="light">Sídlo</span><br>
            {{order.employer.inlineAddress}}
          </div>
        </div>
      </nb-card-body>
    </nb-card>

    <p>Zamestnanec:</p>
    <nb-card>
      <nb-card-body>
        <div class="info-card-grid">
          <div class="info-card-item">
            <span class="light">Meno a priezvisko</span><br>
            {{order.employee.name}} {{order.employee.surname}}
          </div>
          <div class="info-card-divider"></div>
          <div class="info-card-item">
            <span class="light">Dátum narodenia</span><br>
            {{order.employee.dateOfBirth | date}}
          </div>
          <div class="info-card-divider"></div>
          <div class="info-card-item">
            <span class="light">Trvalý pobyt</span><br>
            {{order.employee.inlineAddress}}
          </div>

          <div class="info-card-item">
            <span class="light">Profesia</span><br>
            {{order.occupation.name}}
          </div>
          <div class="info-card-divider"></div>
          <div class="info-card-item">
            <span class="light">Osobné číslo</span><br>
            {{order.employee.personalNumber}}
          </div>
          <div class="info-card-divider"></div>
          <div class="info-card-item">
            <span class="light">Pracovisko</span><br>
            {{order.workplace.name}}
          </div>
        </div>
      </nb-card-body>
    </nb-card>

    <div [ngClass]="{'shift-end': getExaminationTypeName(order.type) != 'Importovaná'}" [hidden]="getExaminationTypeName(order.type) === 'Importovaná'">
      <button nbButton outline status="primary" size="large" (click)="printAssessment()">
        Vytlačiť
      </button>
    </div>
  </div>
</div>
